.dashboard {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    position: relative;
  }
  
  .dashboard h1 {
    text-align: center;
    color: #333;
  }
  
  .dashboard .logout-link {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 0.8em;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .dashboard .form-group {
    flex: 1;
    margin-right: 10px;
  }
  
  .dashboard label {
    margin-bottom: 5px;
    color: #555;
  }
  
  .dashboard .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .dashboard .form-row label {
    display: block;
  }
  
  .dashboard .form-row select,
  .dashboard .form-row input[type="time"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .dashboard .form-row input[type="time"] {
    font-size: 1.2em;
  }
  
  .dashboard .button-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .dashboard .button-row button {
    width: 48%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .dashboard .button-row button:hover {
    background-color: #0056b3;
  }
  
  .dashboard ul {
    list-style: none;
    padding: 0;
  }
  
  .dashboard li {
    padding: 10px;
    margin-bottom: 5px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dashboard li .record-details {
    display: flex;
    justify-content: space-between;
    width: 75%; /* 左側の4分の3のスペース */
  }
  
  .dashboard li .record-details div {
    flex: 1;
    text-align: center;
  }
  
  .dashboard li button {
    padding: 5px 10px;
    background-color: #dc3545;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    width: 25%; /* 横幅の4分の1程度のサイズに設定 */
  }
  
  .dashboard li button:hover {
    background-color: #c82333;
  }
  